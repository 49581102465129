.col {
  height: 100% !important;
}

.card {
  height: 100% !important;
  text-align: center !important;
  background-color: transparent !important;
}

.card:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.icon {
  line-height: 52px !important;
  background: hsl(206, 75%, 49%) !important;
  margin-bottom: 12px !important;
}

.icon svg {
  vertical-align: sub;
}
