.header {
  z-index: 1000;
  width: 100vw;
  padding: 0 4% !important;
}

.navbar {
  background: transparent !important;
  border: none !important;
}

.drawer {
  background: transparent !important;
  border: none !important;
}

.item {
  border: none !important;
  background: none !important;
  font-weight: 800;
}

.item::after {
  content: none !important;
}

.title {
  font-size: 20;
  font-weight: 600;
}

.icon {
  vertical-align: middle;
  margin: 0;
  padding: 0;
}

.submenu {
  width: 100% !important;
}
