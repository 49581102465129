.col {
  height: 100% !important;
}

.card {
  height: 100% !important;
  text-align: center !important;
  background-color: transparent !important;
}

.icon {
  line-height: 83px !important;
  background: hsl(206, 75%, 49%) !important;
  margin-bottom: 20px !important;
}

.icon svg {
  vertical-align: sub;
}
