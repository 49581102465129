.container {
  padding: 4% 8%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.shadow {
  padding: 4% 8%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  -moz-box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 10px #000000;
  z-index: 2;
}

.title {
  margin-bottom: 24px;
}
