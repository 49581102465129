.full {
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  background-size: cover !important;
  background-position: top !important;
  background-attachment: fixed !important;
  background-image: url(../../../assets/images/top.svg) !important;
}

.profile {
  border-radius: 24vh !important;
  background-color: whitesmoke !important;
  width: 24vh;
  height: 24vh;
  border: solid;
  border-width: 4px !important;
  object-fit: cover;
  margin: 8vh;
}

.text {
  text-align: center !important;
}

.icon {
  font-size: 32px;
  margin: 10px;
  padding: 2px;
  position: absolute;
  bottom: 0;
  left: calc(50% - 32px);
}
