@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@900&display=swap");
@import url("https://fonts.googleapis.com/earlyaccess/notokufiarabic.css");

* {
  font-family: "Josefin Sans", "Noto Kufi Arabic", "sans-serif";
}

:-webkit-autofill::first-line,
:-webkit-autofill,
:-webkit-autofill:hover,
:-webkit-autofill:focus,
:-webkit-autofill:active {
  font-family: "Josefin Sans", "Droid Arabic Kufi", "sans-serif" !important;
}

/* antd fullscreen modal */

.ant-modal,
.ant-modal-content {
  height: 100vh !important;
  width: 100vw !important;
  margin: 0 !important;
  top: 0 !important;
  left: 0 !important;
}
.ant-modal-body {
  height: calc(100vh - 110px) !important;
}
