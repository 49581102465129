.full {
  min-height: 100vh !important;
  background-size: cover !important;
  background-position: center !important;
  background-attachment: fixed !important;
  background-image: url(../../../assets/images/middle.svg) !important;
}

.card {
  margin-bottom: 20px !important;
}

.img {
  object-fit: cover;
  height: 220px !important;
}
